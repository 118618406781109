<template>
  <div>
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default">
            <i
              class="fal fa-calendar"
              style="margin: 0 10px 0 0; font-size: 20px"
            ></i>
            <span @click="main()">{{ $t("e-approve.title") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'employee_e_approve.index'"
          >
            <span v-show="true">/</span>
            <span>{{ $t("e-approve.history_approve") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'employee_e_approve.create'"
          >
            <span v-show="true">/</span>
            <span>{{ $t("e-approve.breadcrumbs_create") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'employee_e_approve.approve'"
          >
            <span v-show="true">/</span>
            <span>{{ $t("e-approve.breadcrumbs_approve") }}</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>
    <div class="section-content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    main() {
      this.$router
        .push({
          name: "employee_e_approve.index",
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.breadcrumbs-hover {
  font-size: 18px;
}
.section-content {
  margin-top: 10px;
  padding-top: 30px;
  border-top: 1px solid rgb(95, 95, 95);
}
</style>
